nav {
  float: right;
}

#icons2 {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

.icon2 {
  height: 30px;
}

nav ul {
  position: fixed;
  list-style: none;
  padding: 1em 0 1em 0;
  margin: 0;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(18, 19, 20, 0.95);
  z-index: 3;
  transition: top 0.3s ease;
}

nav ul li a {
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.5em;
}

nav ul li {
  font-size: 1.6rem;
}

.exit-btn {
  float: right;
  margin: 0.3em 0.5em 0 0;
  cursor: pointer;
}

.hide-mobile {
  top: -100%;
}

.current-screen {
  font-weight: 500;
}

@media screen and (min-width: 780px) {
  nav ul li {
    padding: 0 1rem;
    font-size: clamp(1.6rem, 2vw, 3rem);
    float: left;
  }

  #icons2 {
    display: none;
  }

  .show-desktop {
    display: block;
  }

  .hamburger {
    display: none;
  }

  nav {
    float: none;
    display: flex;
    justify-content: center;
  }

  nav ul {
    position: inherit;
    width: auto;
    background: none;
  }
  .cool-link::after {
    content: "";
    display: block;
    width: 0;
    height: 3px;
    background: white;
    transition: width 0.3s;
  }

  .cool-link:hover::after {
    width: 100%;
    transition: width 0.3s;
  }
}

@media screen and (min-width: 1024px) {
  nav ul li {
    padding: 0 3rem;
  }
  #nav-btn {
    display: flex;
    justify-content: space-around;
    padding: 10px;
  }
}

@media screen and (min-width: 1400px) {
  nav ul li {
    padding: 0 5rem;
  }
}

@media screen and (max-width: 1000px) {
  #icons-desktop {
    display: none;
  }
  #icons2-desktop {
    display: none;
  }
}
